import { useEffect } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../button/primary";

function DeleteConfirm({
  isOpen,
  toggleOpen,
  selectedDeleteItem,
  handleDeleteSuccess,
  onDelete,
}) {
  const cancelButtonRef = useRef(null);

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    toggleOpen();
  };

  const handleDelete = () => {
    onDelete(selectedDeleteItem.id, selectedDeleteItem.secondaryId)
      .then(() => {
        handleDeleteSuccess();
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle any errors that occur during submission
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={toggleOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-8 w-8 text-gray-300 -mt-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 capitalize"
                    >
                      Delete {selectedDeleteItem.identifier}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <span className="block py-2">
                          Are you sure you want to delete{" "}
                          <strong className="capitalize">
                            {selectedDeleteItem.identifier}
                          </strong>
                          ?
                        </span>
                        <span className="block">
                          All associated data will be permanently deleted.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 sm:ml-10 sm:mt-6 sm:flex sm:pl-4">
                  <PrimaryButton label="Delete" onClick={handleDelete} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { DeleteConfirm };

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TrashIcon } from "@heroicons/react/24/outline";

function DraggableList({ item, handleRemoveItem }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    // <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
    //     {props.children}
    // </button>
    <div className="table-row" ref={setNodeRef} style={style}>
      <div className="pt-4 pr-4 pb-4 pl-2 mb-2 border border-gray-100 border-solid rounded-md text-sm text-gray-600 text-left hover:bg-gray-25">
        <div className="table-cell pr-2 pt-1">
          <button {...listeners} {...attributes}>
            <svg
              width="30"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 6.75C17.5858 6.75 17.25 6.41421 17.25 6C17.25 5.58579 17.5858 5.25 18 5.25C18.4142 5.25 18.75 5.58579 18.75 6C18.75 6.41421 18.4142 6.75 18 6.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 12.75C17.5858 12.75 17.25 12.4142 17.25 12C17.25 11.5858 17.5858 11.25 18 11.25C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 18.75C17.5858 18.75 17.25 18.4142 17.25 18C17.25 17.5858 17.5858 17.25 18 17.25C18.4142 17.25 18.75 17.5858 18.75 18C18.75 18.4142 18.4142 18.75 18 18.75Z"
                stroke="#3F4047"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <p className="table-cell align-middle w-7/12">{item.checkpoint_name}</p>
        {/* <p className="flex-auto w-16 pt-1">{checkpoint.checkpoint_description}</p> */}
        <p className="table-cell align-middle w-5/12">{item.type_name}</p>
        <p className="table-cell align-middle w-1/12">
          <TrashIcon
            className="h-4 w-4 shrink-0 hover:cursor-pointer"
            onClick={() => handleRemoveItem(item.checkpoint_name, item.id)}
          />
        </p>
      </div>
    </div>
  );
}

export { DraggableList };

import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import "react-phone-number-input/style.css";
import { IconButton } from "../../../../components/button/icon";

const EmergencyContacts = ({ locationId, contactTypes }) => {
  const { control, register, getValues, setValue, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emergency_contacts",
  });

  return (
    <div className="pt-2">
      <fieldset className="sm:col-span-3">
        <legend className="block text-sm font-medium leading-6 text-gray-900">
          Emergency contacts
        </legend>

        {fields.map((field, index) => (
          <div key={field.id} className="flex mt-2 items-center">
            <Controller
              name={`emergency_contacts[${index}].type_id`}
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  onChange={(e) => {
                    // Convert the selected value to a number before setting it
                    field.onChange(parseInt(e.target.value, 10));
                  }}
                  className="flex-1 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
                >
                  <option value="">Select type</option>
                  {contactTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              )}
            />
            <Controller
              control={control}
              name={`emergency_contacts[${index}].number`}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country="US"
                  className="flex-1 rounded-md border-0 py-2 ml-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
                />
              )}
            />
            <IconButton
              type="remove"
              onClick={() => remove(index)}
              className="ml-2"
              style="tight"
            />
          </div>
        ))}
        <IconButton
          type="add"
          onClick={() => append({ type_id: "", number: "" })}
          style="padded"
        />
      </fieldset>
    </div>
  );
};

export { EmergencyContacts };

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RoutesWrapper } from "./routes/RoutesWrapper";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <RoutesWrapper />
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;

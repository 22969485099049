import React from "react";
import { useFormContext } from "react-hook-form";

function Button(props) {
  const { register, formState } = useFormContext();
  const hasErrors = Object.keys(formState.errors).length > 0;
  return (
    <div className="col-span-full">
      <button
        onClick={props.onClick ? props.onClick : undefined}
        type="submit"
        disabled={hasErrors}
        className={`rounded-md bg-brand-primary px-6 py-3 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
          hasErrors ? "opacity-25 cursor-not-allowed" : ""
        }`}
      >
        {props.label}
      </button>
    </div>
  );
}
export { Button };

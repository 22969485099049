import React from "react";

function PrimaryButton({ label, onClick }) {
  return (
    <div className="col-span-full">
      <button
        onClick={onClick}
        className="w-full float-right inline-block rounded-md bg-brand-primary px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {label}
      </button>
    </div>
  );
}
export { PrimaryButton };

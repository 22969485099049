import React from "react";

const SmallHeading = ({ headingLevel, children }) => {
  const HeadingTag = headingLevel || "h1";

  return (
    <div>
      {React.createElement(
        HeadingTag,
        {
          className:
            "text-xsm font-semibold text-gray-500 uppercase tracking-widest",
        },
        children
      )}
    </div>
  );
};

export { SmallHeading };

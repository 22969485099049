import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/app-loading.json";

const PageLoadingAnimation = ({ isLoading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!isLoading) return null;

  return (
    <div className="fixed top-0 lg:left-80 right-0 bottom-0 bg-black bg-opacity-100 flex justify-center items-center z-50">
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};

export { PageLoadingAnimation };

import React, { useContext, useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo-dark.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { PrimaryButton } from "../../../components/button/primary";

function Logout() {
  const { logout, login } = useKindeAuth();
  const navigate = useNavigate();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const authOrgId = authContext.authOrgId ? authContext.authOrgId.orgCode : "";

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await logout();
        localStorage.removeItem("authToken"); // Clear local storage
        setAuthContext(null); // Clear auth context
        navigate("/auth/login"); // Redirect to login
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };

    const timer = setTimeout(handleLogout, 1500);

    return () => clearTimeout(timer);
  }, [logout, setAuthContext, navigate]);

  return (
    <div className="flex h-screen justify-center">
      <div className="w-4/12 bg-white flex items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="leading-relaxed pt-12 w-full text-center">
          You are being logged out. <br /> If you are not redirected,{" "}
          <a
            className="text-brand-primary underline-offset-4 underline hover:no-underline hover:cursor-pointer"
            onClick={() => logout()}
          >
            logout here
          </a>
          .
        </div>
        <div className="pt-10">
          <PrimaryButton
            label="Log back in"
            onClick={() => login({ org_code: authOrgId })}
          />
        </div>
      </div>
    </div>
  );
}

export { Logout };

import { useState, useEffect, useContext } from "react";
import { MultiSelect } from "../../components/menu/multiSelect";
import { AuthContext } from "../../contexts/AuthContext";

function DashboardUserFilter({ selected, onUpdate, onClear }) {
  const [usersOptions, setUsersOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;

  const fetchUsers = async () => {
    if (!orgId) return;

    // Construct the URL with query parameters
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/web/dashboard/users`,
      window.location.origin
    );
    url.searchParams.append("org_id", orgId); // Append orgId as a query parameter

    console.log("API URL", url);

    try {
      const response = await fetch(url.toString()); // No need for requestOptions in a GET request

      if (response.ok) {
        const data = await response.json();
        console.log("Users data:", data);

        const augmentedData = data.map((user) => ({
          ...user,
          name: `${user.first_name} ${user.last_name}`,
        }));

        setUsersOptions(augmentedData);
        setIsLoading(false);
      } else {
        console.error("Error fetching users:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [orgId]);

  return (
    <MultiSelect
      label="Users"
      options={usersOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={isLoading}
      optionStatus="true"
    />
  );
}

export { DashboardUserFilter };

import { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";

function MultiSelect({
  label,
  options,
  onUpdate,
  onClear,
  selected,
  isLoading,
  optionStatus,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // const handleItemClick = (optionId) => {
  //   onUpdate((currentSelected) => {
  //     if (currentSelected.includes(optionId)) {
  //       return currentSelected.filter((item) => item !== optionId);
  //     } else {
  //       return [...currentSelected, optionId];
  //     }
  //   });
  // };

  const handleItemClick = (optionId) => {
    // Call the onUpdate function passed from the parent with the optionId
    onUpdate(optionId);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const handleKeyDown = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(document.activeElement)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          className={`h-10 inline-flex gap-x-1.5 rounded-md px-3 -ml-1 py-2 items-center text-sm leading-none font-normal text-gray-900 border-0 ring-1 ring-gray-100 hover:bg-gray-50 ${
            isDropdownOpen ? "bg-gray-50 ring-2 ring-gray-100" : "bg-gray-25"
          }`}
          onClick={toggleDropdown}
        >
          <span className="text-gray-800">{label}:</span>
          <span className="text-gray-500">
            {selected.length > 0 ? selected.length + " selected" : " All"}
          </span>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </button>
      </div>

      <Transition
        show={isDropdownOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="p-4 max-h-60 overflow-y-auto">
          {isLoading ? (
            <p className="text-gray-600 text-sm">Loading...</p>
          ) : options.length === 0 ? (
            <p className="text-gray-600 text-sm">
              No {label.toLowerCase()} found
            </p>
          ) : (
            <>
              <div className="pb-2 flex items-center justify-between">
                <p className="text-xsm uppercase tracking-wider text-gray-500">
                  {label}
                </p>
                <button
                  className="text-sm text-brand-primary hover:underline"
                  onClick={onClear} // Assuming you want to clear the options here
                >
                  Clear
                </button>
              </div>
              {options.map((option) => (
                <div
                  key={option.id}
                  className={`pl-2 py-2 -ml-2 -mr-2 text-sm text-gray-600 cursor-pointer flex items-center justify-between hover:bg-gray-50 rounded-md ${
                    optionStatus && option.is_active !== 1
                      ? "opacity-50 cursor-not-allowed hover:bg-white"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      !optionStatus ||
                      (optionStatus && option.is_active !== 0)
                    ) {
                      onUpdate(option.id);
                    }
                  }}
                >
                  <div className="flex items-center gap-2">
                    {optionStatus && ( // Conditionally render the span if optionStatus is true
                      <span
                        className={`mr-2 h-2 w-2 rounded-full inline ${
                          option.is_active === 1 ? "bg-success" : "bg-danger"
                        }`}
                      />
                    )}
                    {option.name}
                  </div>
                  <div className="mr-2 h-5 w-5">
                    {selected.includes(option.id) && option.is_active === 1 && (
                      <CheckIcon className="text-brand-primary" />
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Transition>
    </div>
  );
}

export { MultiSelect };

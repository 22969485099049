import React, { useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IconButton } from "../../../../../components/button/icon";
import { TextInput } from "../../../../../components/textInput/index";

const CheckpointChecklist = ({ label }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklist_items",
  });

  return (
    <div className="sm:col-span-3 pt-2">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      {fields.map((field, index) => (
        <div className="flex w-full flex-col" key={field.id}>
          <div className="flex w-full items-center">
            <div className="flex-grow">
              <input
                type="hidden"
                {...register(`checklist_items[${index}].item_id`)}
              />
              <TextInput
                {...register(`checklist_items[${index}].item_text`)}
                placeholder="Checklist item"
              />
            </div>
            <IconButton
              type="remove"
              onClick={() => remove(index)}
              className="ml-2"
              style="tight"
            >
              Remove
            </IconButton>
          </div>
          <p className="w-full mt-1 text-sm text-red-500">
            {errors.checklist_items &&
              errors.checklist_items[index]?.item_text?.message}
          </p>
        </div>
      ))}

      <IconButton
        type="add"
        onClick={() => append({ item_id: null, item_text: "" })}
        className="mt-2"
        style="padded"
      >
        Add a list item
      </IconButton>
    </div>
  );
};

export { CheckpointChecklist };

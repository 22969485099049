import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";

const AuthCallback = () => {
  const { getToken, isAuthenticated, isLoading } = useKindeAuth();
  const navigate = useNavigate();
  const { setAuthContext } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        const syncUserData = async () => {
          try {
            const token = await getToken();
            if (!token) {
              throw new Error("Token is not available");
            }

            const decodedToken = jwtDecode(token);

            if (process.env.REACT_APP_ENV === "development") {
              localStorage.setItem("authToken", token);
            }

            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/v1/users/sync`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  auth_user_id: decodedToken.sub,
                  auth_email: decodedToken.email,
                  auth_org_code: decodedToken.org_code,
                }),
              }
            );

            if (response.ok) {
              setAuthContext({
                userId: decodedToken.sub,
                authId: decodedToken.sub,
                orgId: decodedToken.org_code,
                authOrgId: decodedToken.org_code,
                firstName: decodedToken.given_name,
                lastName: decodedToken.family_name,
                email: decodedToken.email,
              });

              setTimeout(() => {
                navigate("/");
              }, 1500);
            } else {
              console.error("Failed to sync user data");
            }
          } catch (error) {
            console.error("Error syncing user data", error);
          }
        };

        syncUserData();
      } else {
        navigate("/auth/login");
      }
    }
  }, [isAuthenticated, isLoading, getToken, navigate, setAuthContext]);

  return isLoading ? <div>Loading...</div> : null;
};

export { AuthCallback };

import React from "react";
import { SmallHeading } from "../../../components/type/smallHeading";
import { StatusIndicator } from "../../../components/statusIndicator";

const ReportingActivityIncidentReports = ({ details }) => {
  console.log("Incident report details", details);

  const severityLevel = details.severity_level_description.toLowerCase();
  let severityLevelDetails;

  switch (severityLevel) {
    case "low":
      severityLevelDetails = <StatusIndicator text="Low" type="success" />;
      break;
    case "medium":
      severityLevelDetails = <StatusIndicator text="Medium" type="warning" />;
      break;
    case "high":
      severityLevelDetails = <StatusIndicator text="High" type="danger" />;
      break;
    default:
      severityLevelDetails = null;
      break;
  }

  return (
    <>
      <div>
        <div className="-mt-2">
          <div className="flex pb-2">
            <h3 className="w-[50%] text-gray-500 text-sm">Customer name:</h3>
            <p className="text-gray-600 text-sm">{details.customer_name}</p>
          </div>
          <div className="flex pb-2">
            <h3 className="w-[50%] text-gray-500 text-sm">Location name:</h3>
            <p className="text-gray-600 text-sm">{`${details.location_name}`}</p>
          </div>
          <div className="flex pb-2">
            <h3 className="w-[50%] text-gray-500 text-sm">
              Incident category:
            </h3>
            <p className="text-gray-600 text-sm">{`${details.incident_category_name}`}</p>
          </div>
          <div className="flex pb-2">
            <h3 className="w-[50%] text-gray-500 text-sm">
              Incident severity:
            </h3>
            <p className="text-gray-600 text-sm pl-2">{severityLevelDetails}</p>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <SmallHeading>Incident details</SmallHeading>
        <div className="pt-2">
          {details.incident_details && details.incident_details.length > 0 ? (
            details.incident_details?.map((detail, index) => (
              <div key={index} className="flex pb-2">
                <h3 className="w-[50%] text-gray-500 text-sm">
                  {detail.incident_key}:
                </h3>
                <p className="text-gray-600 text-sm">{detail.incident_value}</p>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500 pb-2">No details provided</p>
          )}
        </div>
      </div>
      <div className="pt-6">
        <SmallHeading>Contacts</SmallHeading>
        <div className="pt-2">
          {details.incident_contacts && details.incident_contacts.length > 0 ? (
            details.incident_contacts?.map((contact, index) => (
              <div key={index} className="mb-2">
                {details.incident_contacts.length > 1 && (
                  <h2 className="pb-1 text-sm font-bold text-gray-500">
                    Contact {index + 1}
                  </h2>
                )}
                <div className="flex pb-2">
                  <h3 className="w-[50%] text-gray-500 text-sm">Name:</h3>
                  <p className="text-gray-600 text-sm">{contact.name}</p>
                </div>
                <div className="flex pb-2">
                  <h3 className="w-[50%] text-gray-500 text-sm">Role:</h3>
                  <p className="text-gray-600 text-sm">{contact.role}</p>
                </div>
                <div className="flex pb-2">
                  <h3 className="w-[50%] text-gray-500 text-sm">Email:</h3>
                  <p className="text-gray-600 text-sm">{contact.email}</p>
                </div>
                <div className="flex">
                  <h3 className="w-[50%] text-gray-500 text-sm">Phone:</h3>
                  <p className="text-gray-600 text-sm">{contact.phone}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500 pb-2">No contacts provided</p>
          )}
        </div>
      </div>
    </>
  );
};

export { ReportingActivityIncidentReports };

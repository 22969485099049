import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, isLoading, getToken, user, getOrganization } =
    useKindeAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  const [authContext, setAuthContext] = useState({
    userId: "",
    authId: "",
    orgId: "",
    authOrgId: "",
    accessLevel: 1,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    imageUrl: "",
  });
  useEffect(() => {
    console.log("Is authenticated:", isAuthenticated);
    console.log("Is loading:", isLoading);
  }, [isAuthenticated, isLoading]);

  // Redirect based on authentication state
  useEffect(() => {
    if (!isLoading && !initialCheckDone) {
      if (!isAuthenticated && location.pathname !== "/auth/login") {
        navigate("/auth/login");
      } else if (isAuthenticated && location.pathname === "/auth/callback") {
        navigate("/");
      }
      setInitialCheckDone(true);
    }
  }, [
    isAuthenticated,
    isLoading,
    navigate,
    location.pathname,
    initialCheckDone,
  ]);

  // Fetch user data if authenticated
  useEffect(() => {
    if (user) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/web/auth/login/${user.id}?detail=true`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const localUserData = data[0];
          setAuthContext({
            userId: localUserData.id,
            authId: user.id,
            orgId: localUserData.org_id,
            authOrgId: getOrganization(),
            accessLevel: "1",
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email,
            phoneNumber: localUserData.phone_number,
            imageUrl: localUserData.profile_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [user, getOrganization]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        getToken,
        authContext,
        setAuthContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

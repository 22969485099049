import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { TextInput } from "../../../components/textInput";
import { ImageInput } from "../../../components/imageInput";
import { SideSheet } from "../../../components/sheet/sideSheet";
import { Toast } from "../../../components/toast";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

/// Form validation schema ///
const schema = z.object({
  customer_name: z.string().min(1, "Customer name is required"),
  contact_name: z.string(),
  contact_phone: z.string(),
});

function CustomerDetails({
  isOpen,
  toggleOpen,
  selectedCustomerId,
  isAddMode,
  onSuccess,
}) {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;
  const [imageFile, setImageFile] = useState(null);

  /// Form ///
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      customer_name: "",
      contact_name: "",
      contact_phone: "",
      logo_url: "",
    },
    resolver: zodResolver(schema),
  });

  const { reset, handleSubmit, formState } = methods;

  useEffect(() => {
    if (isOpen) {
      reset({
        customer_name: "",
        contact_name: "",
        contact_phone: "",
        logo_url: "",
      });
      handleImageClear(); // Clear image when the form is opened
    }
  }, [isOpen, reset]);

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const [clearImageTrigger, setClearImageTrigger] = useState(false);

  const handleImageClear = () => {
    setClearImageTrigger((prev) => !prev);
  };

  const clearForm = () => {
    handleImageClear(); // Clear the image
    reset({
      customer_name: "",
      contact_name: "",
      contact_phone: "",
      logo_url: "",
    });
  };

  const onSubmit = (data) => {
    return isAddMode ? createCustomer(data) : updateCustomer(data);
  };

  const handleSave = handleSubmit(onSubmit);

  /// Create customer ///
  function createCustomer(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (imageFile) {
      formData.append("file", imageFile);
    }
    formData.append("org_id", orgId);

    fetch(`${process.env.REACT_APP_API_URL}/web/admin/customers/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(`Fetch returned status ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        onSuccess();
        handleResponse("Customer created successfully", "success");
        clearForm(); // Ensure the form and image are cleared after successful creation
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create customer", "danger");
      });
  }

  /// Update customer ///
  function updateCustomer(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (imageFile) {
      formData.append("file", imageFile);
    }
    formData.append("org_id", orgId);

    fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/customers/${selectedCustomerId}`,
      {
        method: "PUT",
        body: formData,
      }
    )
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        onSuccess(); // Call onSuccess immediately after the successful operation
        handleResponse("Customer updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update customer", "danger");
      });
  }

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  const [backendData, setBackendData] = useState({
    customer_name: "",
    contact_name: "",
    contact_phone: "",
    logo_url: "",
  });

  /// Fetch data for existing customer ///
  useEffect(() => {
    if (!isAddMode && selectedCustomerId !== null) {
      fetch(
        `${process.env.REACT_APP_API_URL}/web/admin/customers/${selectedCustomerId}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data[0]) {
            const { customer_name, contact_name, contact_phone } = data[0];

            setBackendData({
              customer_name,
              contact_name,
              contact_phone,
              logo_url: data[0].logo_url,
            });

            reset({
              customer_name,
              contact_name,
              contact_phone,
              logo_url: data[0].logo_url,
            });
          } else {
            console.error("No data available for the given customer ID");
          }
        })
        .catch((error) => {
          console.error("Fetch failed:", error);
        });
    }
  }, [selectedCustomerId, isAddMode, reset]);

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /// Image upload ///
  function handleImageChange(event) {
    // Get the file from the event
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }

    setImageFile(file);
  }

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={open}
          onClose={toggleOpen}
          title={!isAddMode ? "Customer details" : "Create customer"}
          isAddMode={isAddMode}
          footerText="Create customer"
          onError={onError}
          handleSave={handleSave}
        >
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-3">
              <TextInput name="customer_name" label="Customer name" />
              <TextInput name="contact_name" label="Customer contact" />
              <TextInput name="contact_phone" label="Customer phone" />
              <ImageInput
                name="logoImage"
                label="Logo"
                onChange={handleImageChange}
                accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
                maxFileSize={3201010}
                existing={isAddMode ? null : backendData.logo_url}
                onClear={clearImageTrigger} // Passing the state directly
              />
            </div>
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}

export { CustomerDetails };

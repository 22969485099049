import React, { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";

function Tours({
  handleToursAddMode,
  setIsCheckpointsAddMode,
  fetchToursList,
  toursListData,
}) {
  useEffect(() => {
    fetchToursList();
  }, []);

  return (
    <div>
      {typeof toursListData === "undefined" ? (
        <p>Loading...</p>
      ) : (
        <table className="mt-2 w-full">
          <thead className="text-xs uppercase text-left">
            <tr>
              <th className="pl-6 font-normal text-gray-500">Name</th>
              <th className="pl-6 font-normal text-gray-500">Description</th>
              <th className="pl-6 font-normal  text-gray-500">Checkpoints</th>
              <th className="pl-6 font-normal text-gray-500">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {toursListData.map((tour) => (
              <tr
                className="border-solid border-b border-gray-100"
                key={tour.id}
              >
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm ">
                  <a
                    onClick={() => {
                      handleToursAddMode(tour.id);
                      setIsCheckpointsAddMode(true);
                    }}
                    className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
                  >
                    {tour.tour_name}
                  </a>
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {tour.tour_description}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {tour.checkpoint_names}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-right text-sm font-medium  hover:text-gray-600">
                  <TrashIcon className="h-4 w-4 shrink-0 hover:cursor-pointer hover:text-gray-600" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export { Tours };

import React from "react";

const StatusIndicator = ({ text, type }) => {
  let typeStyle;

  switch (type) {
    case "danger":
      typeStyle = "bg-danger text-white";
      break;
    case "warning":
      typeStyle = "bg-alert text-gray-600";
      break;
    case "success":
      typeStyle = "bg-success text-white";
      break;
    default:
      typeStyle = "bg-gray-50 text-gray-500";
      break;
  }

  return (
    <span
      className={`py-1 px-2 -ml-2 mb-1 rounded-md block text-xxsm uppercase font-normal tracking-wider ${typeStyle}`}
    >
      {text}
    </span>
  );
};

export { StatusIndicator };

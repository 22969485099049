import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

function Toast({ show, message, type, onHide }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);

  // Icons and colors for different toast types
  const toastTypes = {
    success: {
      icon: CheckCircleIcon,
      bgColor: "bg-success",
      textColor: "text-white",
    },
    alert: {
      icon: ExclamationCircleIcon,
      bgColor: "bg-alert",
      textColor: "text-white",
    },
    danger: {
      icon: XCircleIcon,
      bgColor: "bg-danger",
      textColor: "text-white",
    },
  };

  const Icon = toastTypes[type]?.icon;
  const bgColor = toastTypes[type]?.bgColor;
  const textColor = toastTypes[type]?.textColor;

  return (
    <Transition
      show={show}
      as={React.Fragment}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 translate-y-2"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition ease-in duration-300"
      leaveFrom="transform opacity-100 translate-y-0"
      leaveTo="transform opacity-0 translate-y-2"
    >
      <div
        className={`fixed bottom-0 right-0 mb-4 mr-4 z-50 ${bgColor} ${textColor} p-4 rounded-md shadow-lg flex items-center`}
      >
        {Icon && <Icon className="w-5 h-5 mr-2" />}
        {message}
      </div>
    </Transition>
  );
}

export { Toast };

import { useState, useEffect, useContext } from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";
import { AuthContext } from "../../../contexts/AuthContext";

function ReportingUserFilter({ selected, onUpdate, onClear }) {
  const [usersOptions, setUsersOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { authContext } = useContext(AuthContext);
  const { orgId } = authContext;

  const fetchUsers = async () => {
    if (!orgId) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/admin/users?org_id=${orgId}`
      );

      if (response.ok) {
        const data = await response.json();

        const augmentedData = data.map((user) => ({
          ...user,
          name: user.first_name + " " + user.last_name,
        }));

        setUsersOptions(augmentedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching users");
      }
    } catch (error) {
      console.log("Error fetching users");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [orgId]);

  return (
    <MultiSelect
      label="Users"
      options={usersOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={isLoading}
    />
  );
}

export { ReportingUserFilter };

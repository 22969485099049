import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";

function CustomerSelectMenu(props) {
  const { control, formState } = useFormContext();
  const [customerList, setCustomerList] = useState([]);
  const [selected, setSelected] = useState([]);

  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/customers?org_id=${orgId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCustomerList(data);
      });
  }, [orgId]);

  const options = customerList.map((customer) => ({
    value: customer.id,
    label: customer.customer_name,
  }));

  const name = props.name;
  const label = props.label;

  useEffect(() => {
    const defaultValues = formState.defaultValues[name];
    if (defaultValues) {
      setSelected(defaultValues);
    }
  }, [formState.defaultValues, name]);

  const handleSelectChange = (selectedOptions) => {
    setSelected(selectedOptions.map((option) => option.value));
  };

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              isMulti
              options={options}
              value={options.filter((option) =>
                selected.includes(option.value)
              )}
              onChange={(selectedOptions) => {
                handleSelectChange(selectedOptions);
                field.onChange(selectedOptions.map((option) => option.value));
              }}
            />
          )}
        />
        <p className="h-2 pt-1 text-sm">{formState.errors[name]?.message}</p>
      </div>
    </div>
  );
}

export { CustomerSelectMenu };

import React, { useState, useEffect, useRef } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_API_KEY } from "../../config";

const ClockedInLocationMap = ({ clockedInLocationData }) => {
  const [visibleInfoWindowIndex, setVisibleInfoWindowIndex] = useState(null);
  const mapRef = useRef(null); // Create a ref for the map instance
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [camera, setCamera] = useState({ center: null, zoom: 10 });
  const userHasInteracted = useRef(false);

  const toggleInfoWindow = (index) => {
    setVisibleInfoWindowIndex(visibleInfoWindowIndex === index ? null : index);
  };

  const getDefaultBounds = () => {
    if (clockedInLocationData.length === 0) {
      return null; // default bounds when no data
    }
    const latitudes = clockedInLocationData.map((loc) => loc.latitude);
    const longitudes = clockedInLocationData.map((loc) => loc.longitude);
    const north = Math.max(...latitudes);
    const south = Math.min(...latitudes);
    const east = Math.max(...longitudes);
    const west = Math.min(...longitudes);
    // Adjust bounds slightly to avoid too tight zoom
    const latDiff = north - south;
    const lngDiff = east - west;
    return {
      north: north + 0.1 * latDiff,
      south: south - 0.1 * latDiff,
      east: east + 0.1 * lngDiff,
      west: west - 0.1 * lngDiff,
    };
  };

  const mapKey = clockedInLocationData.reduce((key, loc) => {
    return key + `${loc.latitude}${loc.longitude}`;
  }, "");

  const defaultBounds = getDefaultBounds();

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <div className="rounded-xl overflow-hidden">
        <Map
          key={mapKey} // This key change will force the Map to remount when data changes significantly
          defaultBounds={defaultBounds}
          style={{ width: "100%", height: "350px" }}
          mapId="6da3d397758f8b1a"
          options={mapOptions}
        >
          {clockedInLocationData.map((data, index) => (
            <React.Fragment key={index}>
              <AdvancedMarker
                position={{ lat: data.latitude, lng: data.longitude }}
                label={data.name}
                onClick={() => toggleInfoWindow(index)}
              >
                <img
                  src={data.profile_url}
                  alt={data.name}
                  className="w-12 h-12 rounded-full border-2 border-white drop-shadow-200"
                />
              </AdvancedMarker>
              {visibleInfoWindowIndex === index && (
                <InfoWindow
                  position={{ lat: data.latitude, lng: data.longitude }}
                  onClose={() => setVisibleInfoWindowIndex(null)}
                  className="bg-white p-1 rounded-lg shadow"
                  options={{ pixelOffset: { width: 0, height: -55 } }}
                >
                  <div>
                    <div className="pb-1 uppercase font-medium tracking-wider text-xs text-gray-500">
                      Site 123
                    </div>
                    <h2 className="pb-3 font-medium text-md">{data.name}</h2>
                    <p className="text-sm text-gray-500">
                      Additional details can be shown here.
                    </p>
                  </div>
                </InfoWindow>
              )}
            </React.Fragment>
          ))}
        </Map>
      </div>
    </APIProvider>
  );
};

export { ClockedInLocationMap };

import React from "react";

function SecondaryTabs({ tabs, activeTab }) {
  return (
    <>
      {tabs.map((tab) => {
        if (tab.name === activeTab) {
          return (
            <div key={tab.name} className="">
              {tab.component}
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

export { SecondaryTabs };

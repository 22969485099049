import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_API_KEY } from "../../config";

let autoComplete;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

function SearchLocationInput({
  onAddressChange,
  name,
  label,
  defaultAddress,
  onReset,
  isAddMode,
  error,
}) {
  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        fields: ["address_components", "geometry"],
        types: ["address"],
        componentRestrictions: { country: ["us", "ca"] },
      }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  function formatAddress({
    street = "",
    city = "",
    state = "",
    country = "",
    zip = "",
  }) {
    return [street, city, state, country, zip].filter(Boolean).join(", ");
  }

  const [inputValue, setInputValue] = useState(
    defaultAddress ? formatAddress(defaultAddress) : ""
  );

  const handleChange = (event) => {
    setInputValue(event.target.value);
    // inputChangedRef.current = true; // User has made a change to the input
  };

  useEffect(() => {
    if (isAddMode) {
      setInputValue("");
    }
  }, [onReset, isAddMode]);

  const { register, formState, setValue } = useFormContext();

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loader.load().then(() => {
      handleScriptLoad(() => {
        const selectedAddress = autoCompleteRef.current.value;
        setInputValue(selectedAddress);
        setQuery(selectedAddress);
      }, autoCompleteRef);
    });
  }, []);

  useEffect(() => {
    if (defaultAddress) {
      setInputValue(formatAddress(defaultAddress));
      setValue(name, defaultAddress, { shouldValidate: true });
    }
  }, [defaultAddress, setValue, name]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      autoCompleteRef.current.getAttribute("aria-activedescendant")
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    const processedData = processAddressData(addressObject);
    setInputValue(formatAddress(processedData));
    setValue("location_address", processedData);
    onAddressChange(processedData);
  }

  function processAddressData(addressObject) {
    const { formatted_address, address_components } = addressObject;

    let street = "";
    let city = "";
    let state = "";
    let country = "";
    let zip = "";

    address_components.forEach((component) => {
      if (component.types.includes("street_number")) {
        street = component.long_name;
      } else if (component.types.includes("route")) {
        street = `${street} ${component.long_name}`;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zip = component.long_name;
      }
    });

    return {
      street,
      city,
      state,
      country,
      zip,
    };
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          {...register(name)}
          ref={autoCompleteRef}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
        />

        {error && (
          <p className="h-2 pt-1 text-sm">{error.location_address.message}</p>
        )}
      </div>
    </div>
  );
}

export { SearchLocationInput };

import React from "react";
import { useFormContext } from "react-hook-form";

function TextArea(props) {
  const { register, formState } = useFormContext();

  const name = props.name;
  const label = props.label;

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <textarea
          {...register(name)}
          rows={4}
          type="text"
          autoComplete="off"
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
        />
        <p className="h-2 pt-1 text-sm">{formState.errors[name]?.message}</p>
      </div>
    </div>
  );
}

export { TextArea };

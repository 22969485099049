import React, { useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logo-dark.svg";
import { PrimaryButton } from "../../../components/button/primary";

function Login() {
  const { login } = useKindeAuth();
  const location = useLocation();
  const [authOrgId, setAuthOrgId] = useState("");

  useEffect(() => {
    const storedOrgId = localStorage.getItem("authOrgId");
    if (storedOrgId) {
      setAuthOrgId(storedOrgId);
    }
  }, []);

  useEffect(() => {
    console.log("authOrgId:", authOrgId);
  }, [authOrgId]);

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="pt-12 w-full">
          <PrimaryButton
            label="Sign in"
            onClick={() =>
              login({
                org_code: authOrgId,
                app_state: {
                  redirectTo: location.state
                    ? location.state.from.pathname
                    : "/",
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export { Login };

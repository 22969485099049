import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PrintModal } from "../../../../components/modal/printModal";
import { TrashIcon } from "@heroicons/react/24/outline";

function Checkpoints({
  handleCheckpointsAddMode,
  fetchCheckpointsList,
  checkpointsListData,
  handleDeleteCheckpoint,
}) {
  const [isPrintModalOpen, setPrintModalOpen] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);

  useEffect(() => {
    fetchCheckpointsList();
  }, []);

  const handlePrintModalOpen = (checkpoint) => {
    setSelectedCheckpoint(checkpoint);
    setPrintModalOpen(true);
  };

  const handlePrintModalClose = () => {
    setPrintModalOpen(false);
    setSelectedCheckpoint(null);
  };

  return (
    <div>
      {typeof checkpointsListData === "undefined" ? (
        <p>Loading...</p>
      ) : (
        <table className="mt-2 w-full">
          <thead className="text-xs uppercase text-left">
            <tr>
              <th className="pl-6 font-normal text-gray-500">Name</th>
              <th className="pl-6 font-normal text-gray-500">Description</th>
              <th className="pl-6 font-normal text-gray-500">Type</th>
              <th className="pl-6 font-normal text-gray-500">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {checkpointsListData.map((checkpoint) => (
              <tr
                className="border-solid border-b border-gray-100"
                key={checkpoint.id}
              >
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-900">
                  <a
                    onClick={() => {
                      handleCheckpointsAddMode(
                        checkpoint.id,
                        checkpoint.type_id
                      );
                    }}
                    className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
                  >
                    {checkpoint.checkpoint_name}
                  </a>
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {checkpoint.checkpoint_description}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {checkpoint.type_id === 2 ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePrintModalOpen(checkpoint);
                      }}
                      className="text-blue-500 hover:text-blue-700 hover:underline"
                    >
                      {checkpoint.type_name}
                    </a>
                  ) : (
                    checkpoint.type_name
                  )}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-right text-sm font-medium">
                  <TrashIcon
                    className="h-4 w-4 shrink-0 hover:cursor-pointer hover:text-gray-600"
                    onClick={() =>
                      handleDeleteCheckpoint(
                        checkpoint.checkpoint_name,
                        checkpoint.id
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isPrintModalOpen && (
        <PrintModal
          isOpen={isPrintModalOpen}
          toggleOpen={handlePrintModalClose}
          selectedItem={selectedCheckpoint}
          title={`Print QR code for ${selectedCheckpoint?.checkpoint_name}?`}
          message={`Print this QR code and place in the desired location for your team to scan and conduct the checkpoint.`}
          handleSuccess={() => {
            // handle success logic here
          }}
          onPrint={() => {
            // handle print logic here
          }}
        />
      )}
    </div>
  );
}

export { Checkpoints };

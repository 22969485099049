import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { TextInput } from "../../../components/textInput";
import { CustomerSelectMenu } from "../../../components/select/customer";
import { ImageInput } from "../../../components/imageInput";
import { SideSheet } from "../../../components/sheet/sideSheet";
import { Toast } from "../../../components/toast";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const schema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  email: z
    .string()
    .email("Invalid email address")
    .min(1, "Email address is required"),
  phone_number: z.string(),
  customers: z
    .array(z.number())
    .min(1, "At least one customer must be selected"),
});

function UserDetails({
  isOpen,
  toggleOpen,
  selectedUserId,
  isAddMode,
  onSuccess,
}) {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;
  const authOrgId = authContext.authOrgId.orgCode;

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      customers: [],
      profile_url: "",
    },
    resolver: zodResolver(schema),
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (isOpen) {
      reset({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        customers: [],
        profile_url: "",
      });
    }
  }, [isOpen, reset]);

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const [clearImageTrigger, setClearImageTrigger] = useState(false);

  const handleImageClear = () => {
    setClearImageTrigger((prev) => !prev);
  };

  const clearForm = () => {
    handleImageClear(); // Clear the image
    reset({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      customers: [],
      profile_url: "",
    });
  };

  const onSubmit = (data) => {
    return isAddMode ? createUser(data) : updateUser(data);
  };

  const handleSave = handleSubmit(onSubmit);

  /// Images ///
  const [imageFile, setImageFile] = useState(null);

  function createUser(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "customers") {
        formData.append(key, data[key]);
      }
    });
    data.customers.forEach((customer, index) => {
      formData.append(`customers[${index}]`, customer);
    });
    if (imageFile) {
      formData.append("file", imageFile);
    }
    formData.append("org_id", orgId);
    formData.append("auth_org_id", authOrgId);

    fetch(`${process.env.REACT_APP_API_URL}/web/admin/users/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(`Fetch returned status ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        onSuccess();
        handleResponse("User created successfully", "success");
        clearForm();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create user", "danger");
      });
  }

  /// Update user ///
  function updateUser(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "customers") {
        formData.append(key, data[key]);
      }
    });
    data.customers.forEach((customer, index) => {
      formData.append(`customers[${index}]`, customer);
    });
    if (imageFile) {
      formData.append("file", imageFile);
    }
    formData.append("org_id", orgId);

    fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/users/${selectedUserId}`,
      {
        method: "PUT",
        body: formData,
      }
    )
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        onSuccess();
        handleResponse("User updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update user", "danger");
      });
  }

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  const [backendData, setBackendData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    customers: [],
    profile_url: "",
  });

  useEffect(() => {
    if (!isAddMode && selectedUserId !== null) {
      fetch(
        `${process.env.REACT_APP_API_URL}/web/admin/users/${selectedUserId}?detail=detailed`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data[0]) {
            const { first_name, last_name, email, phone_number, customers } =
              data[0];
            const parsedCustomers =
              customers && customers[0].name === null ? [] : customers;

            setBackendData({
              first_name,
              last_name,
              email,
              customers: parsedCustomers,
              profile_url: data[0].profile_url,
            });
            reset({
              first_name,
              last_name,
              email,
              phone_number,
              customers: parsedCustomers.map((customer) => customer.id),
              profile_url: data[0].profile_url,
            });
          } else {
            console.error("No data available for the given user ID");
          }
        })
        .catch((error) => {
          console.error("Fetch failed:", error);
        });
    }
  }, [selectedUserId, isAddMode, reset]);

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /// Image upload ///
  function handleImageChange(event) {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }

    setImageFile(file);
  }

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={open}
          onClose={toggleOpen}
          title={!isAddMode ? "User details" : "Create user"}
          isAddMode={isAddMode}
          footerText="Create user"
          onError={onError}
          handleSave={handleSave}
        >
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-3">
              <TextInput name="first_name" label="First name" />
              <TextInput name="last_name" label="Last name" />
              <TextInput name="email" label="Email address" />
              <TextInput name="phone_number" label="Phone number" />
              <CustomerSelectMenu name="customers" label="Customer access" />
              <ImageInput
                name="userImage"
                label="Upload image"
                onChange={handleImageChange}
                accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
                maxFileSize={3201010}
                existing={isAddMode ? null : backendData.profile_url}
                onClear={clearImageTrigger} // Passing the state directly
              />
            </div>
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}

export { UserDetails };

import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { TextInput } from "../../../../components/textInput";
import { TextArea } from "../../../../components/textArea";
import { Button } from "../../../../components/button";
import { Toast } from "../../../../components/toast";
import { SideSheet } from "../../../../components/sheet/sideSheet";
import { useForm, FormProvider, useWatch, set } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { date, z } from "zod";
import QRCode from "qrcode.react";
import { CheckpointChecklist } from "./checklist";

const schema = z.object({
  checkpoint_name: z.string().min(1, "Checkpoint name is required"),
  checkpoint_description: z
    .string()
    .min(1, "Checkpoint description is required"),
  checkpoint_type: z.string().min(1, "Checkpoint type is required"),
  checklist_items: z
    .array(
      z
        .object({
          item_text: z
            .string()
            .min(5, "Checklist items require at least 5 characters.")
            .optional(),
        })
        .refine(
          (data) => {
            if (data.type_id || data.number) {
              return !!data.type_id && !!data.number;
            }
            return true;
          },
          {
            message:
              "Both type_id and number should be provided if one is given",
            path: ["emergency_contacts"],
          }
        )
    )
    .optional(),
});

function CheckpointDetails({
  isOpen,
  toggleOpen,
  setCheckpoints,
  fetchCheckpoints,
  checkpoints,
  selectedTourId,
  selectedCheckpointId,
  selectedCheckpointDetails,
  setSelectedTourCheckpoints,
  fetchSelectedCheckpointDetails,
  isAddMode,
  selectedCheckpointType,
}) {
  const params = useParams();

  const [existingChecklistItems, setExistingChecklistItems] = useState([]);

  /// Form registraton ///
  const methods = useForm({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      checkpoint_type:
        (isAddMode ? "1" : selectedCheckpointType?.toString()) || "1",
      checklist_items: isAddMode
        ? [{ item_id: null, item_text: "" }]
        : existingChecklistItems.map((item) => ({
            item_id: item.id,
            item_text: item.text,
          })),
    },
  });

  const { register, handleSubmit, control, formState, reset, getValues } =
    methods;

  /// Checklist ///

  useEffect(() => {
    if (!isAddMode && selectedCheckpointId) {
      fetch(
        `${process.env.REACT_APP_API_URL}/web/settings/locations/checkpoints/${selectedCheckpointId}/checklist-items`
      )
        .then((response) => response.json())
        .then((fetchedData) => {
          setExistingChecklistItems(fetchedData); // Set state for other uses (optional)

          // Set form values directly with fetched data
          reset({
            ...getValues(),
            checklist_items: fetchedData.map((item) => ({
              item_id: item.id,
              item_text: item.item_text,
            })),
          });
        })
        .catch((error) =>
          console.error("Error fetching checklist items:", error)
        );
    }
  }, [isAddMode, selectedCheckpointId, reset, getValues]);

  /// Checkpoint types ///
  const [checkPointTypes, setCheckPointTypes] = useState([{}]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/types/checks`
    )
      .then((respose) => respose.json())
      .then((data) => {
        setCheckPointTypes(data);
      });
  }, []);

  const [submitSuccess, setSubmitSuccess] = useState({
    displaying: false,
    message: "Checkpoint added successfully",
  });

  // const [showQRCode, setShowQRCode] = useState(false);

  // // Watch the checkpoint_type field to determine if the QR code should be displayed
  // const checkpointTypeValue = useWatch({
  //   control,
  //   name: "checkpoint_type", // Name of the field you want to watch
  // });

  // // Use useEffect to set the showQRCode state when the component mounts
  // useEffect(() => {
  //   // If in edit mode and the selected type is '2', show the QR code
  //   setShowQRCode(!isAddMode && selectedCheckpointType === 2);
  // }, [isAddMode, selectedCheckpointType]);

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  /// Form submission ///
  const onSubmit = (data) => {
    return isAddMode ? createCheckpoint(data) : updateCheckpoint(data);
  };

  const handleSave = handleSubmit(onSubmit);

  /// Update checkpoint ///
  const updateCheckpoint = (data) => {
    console.log("updateCheckpoint data:", data);
    fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/checkpoints/${selectedCheckpointId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        fetchCheckpoints();
        handleResponse("Checkpoint updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update checkpoint", "danger");
      });
  };

  const createCheckpoint = (data) => {
    // If tourID is not null, add the value to the data object as tourId
    if (selectedTourId) {
      data.tourId = selectedTourId;
    }

    data.position = checkpoints.length + 1;

    fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/${params.id}/checkpoints/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })

      .then((result) => {
        const checkpointTypeId = parseInt(data.checkpoint_type, 10);

        // Find the type_name from checkPointTypes array using the checkpoint_type id
        const selectedType = checkPointTypes.find(
          (type) => parseInt(type.id, 10) === checkpointTypeId
        );

        // Construct newCheckpoint with the type_name instead of the type id
        const newCheckpoint = {
          // ...data,
          id: result.checkpointId,
          checkpoint_name: data.checkpoint_name,
          type_name: selectedType ? selectedType.type_name : "Unknown type",
          position: data.position,
          checkpoint_description: data.checkpoint_description,
        };

        setSubmitSuccess({ displaying: true, message: submitSuccess.message });
        // Update local checkpoints state
        setCheckpoints((prevCheckpoints) => [
          ...prevCheckpoints,
          newCheckpoint,
        ]);

        setSelectedTourCheckpoints((prevCheckpoints) => [
          ...prevCheckpoints,
          newCheckpoint,
        ]);

        fetchCheckpoints();

        reset();

        handleResponse("Checkpoint created successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create checkpoint", "danger");
      });
  };

  /// Edit mode ///
  const fetchCheckpointDetails = async () => {
    if (!isAddMode && selectedCheckpointDetails) {
      fetchSelectedCheckpointDetails();
      const {
        checkpoint_name,
        checkoint_details,
        checkpoint_type,
        checklist_items,
      } = selectedCheckpointDetails;

      reset({
        checkpoint_name,
        checkoint_details,
        checkpoint_type,
        checklist_items,
      });
    }
  };

  useEffect(() => {
    if (isAddMode) {
      // Reset the form when in Add Mode
      reset({
        checkpoint_name: "",
        checkpoint_description: "",
        checkpoint_type: "",
        checklist_items: [{ item_id: null, item_text: "" }],
      });
    } else if (selectedCheckpointDetails) {
      // In Edit mode, set form values based on selectedCheckpointDetails
      reset({
        checkpoint_name: selectedCheckpointDetails.checkpoint_name,
        checkpoint_description:
          selectedCheckpointDetails.checkpoint_description,
        checkpoint_type: selectedCheckpointDetails.checkpoint_type,
      });
    }
  }, [isAddMode, selectedCheckpointDetails, reset]);

  useEffect(() => {
    if (isOpen) {
      fetchCheckpointDetails();
    }
  }, [selectedCheckpointId, isAddMode]);

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={isOpen}
          onClose={toggleOpen}
          title={!isAddMode ? "Checkpoint details" : "Create checkpoint"}
          isAddMode={isAddMode}
          footerText="Create checkpoint"
          // onSubmit={onSubmit}
          onError={onError}
          // handleSubmit={handleSubmit}
          handleSave={handleSave}
        >
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-3">
              <TextInput name="checkpoint_name" label="Checkpoint name" />
              <TextArea
                name="checkpoint_description"
                label="Checkpoint description"
              />
            </div>
            <div>
              <fieldset>
                <legend className="pb-2 text-sm font-medium leading-6 text-gray-900">
                  Type
                </legend>
                <div className="flex items-center mb-4">
                  {checkPointTypes.map((type, key) => (
                    <div key={key} className="flex mr-6 align-middle">
                      <div className="">
                        <input
                          id={type.id}
                          name="checkpoint_type"
                          aria-describedby="checkpoint_type"
                          type="radio"
                          className="focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300"
                          value={type.id}
                          defaultChecked={
                            !isAddMode
                              ? type.id === selectedCheckpointType
                              : type.id === 1
                          }
                          {...register("checkpoint_type")}
                        />
                      </div>
                      <div className="pl-2">
                        <label
                          htmlFor={type.id}
                          className="font-medium text-gray-900"
                        >
                          {type.type_name}
                        </label>
                        {/* <p id="type-description" className="text-gray-500">
                              {type.type_description}
                            </p> */}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <CheckpointChecklist label="Checklist" methods={methods} />
                </div>
              </fieldset>
            </div>
            {/* {showQRCode && (
                  <div className="my-4 flex justify-center">
                    <QRCode
                      value={`Checkpoint ID: ${selectedCheckpointId || "new"}`}
                    />
                  </div>
                )} */}
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}

export { CheckpointDetails };

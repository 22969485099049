import React, { useState, useEffect } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const FixedLocationMap = ({ latitude, longitude }) => {
  const mapOptions = {
    disableDefaultUI: true,
  };

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if (latitude && longitude) {
      setLat(parseFloat(latitude));
      setLng(parseFloat(longitude));
    }
  }, [latitude, longitude]);

  if (lat === null || lng === null) {
    return <div>Loading map...</div>;
  }

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <div className="rounded-xl overflow-hidden">
        <Map
          defaultCenter={{ lat: lat, lng: lng }}
          style={{ width: "100%", height: "150px" }}
          mapId="6da3d397758f8b1a"
          options={mapOptions}
          zoom={13}
        >
          <Marker position={{ lat: lat, lng: lng }} />
        </Map>
      </div>
    </APIProvider>
  );
};

export { FixedLocationMap };

import React, { useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function Profile() {
  const { user, isAuthenticated, isLoading } = useKindeAuth();

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <div>
      <h2>Profile</h2>
      <p>Hi {user.given_name + user.family_name + user.id}!</p>
      <p>Email: {user.email}</p>
    </div>
  ) : (
    <p>Please sign in or register!</p>
  );
}

export { Profile };

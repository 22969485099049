import React, { useEffect, useRef, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../button/primary";
import QRCode from "qrcode.react";
import { useReactToPrint } from "react-to-print";

import { QRPrint } from "../../containers/settings/locations/checkpoints/qrPrint";

function PrintModal({ isOpen, toggleOpen, selectedItem, title, message }) {
  const cancelButtonRef = useRef(null);
  const printableRef = useRef();

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    toggleOpen();
  };

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
    documentTitle: "QR Code",
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-500"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-6 flex justify-center items-center">
                      <div>
                        <QRCode
                          value={`${process.env.REACT_APP_DEEP_LINK_URL}/checkpoint/${selectedItem.id}`}
                          size={128}
                        />
                      </div>
                      <div className="px-6">
                        <p className="text-sm text-gray-500">{message}</p>
                        <div className="w-32 mt-3">
                          <PrimaryButton label="Print" onClick={handlePrint} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Hidden element for printing */}
                <div style={{ display: "none" }}>
                  <QRPrint
                    ref={printableRef}
                    qrValue={`${process.env.REACT_APP_DEEP_LINK_URL}/checkpoint/${selectedItem.id}`}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { PrintModal };

import React from "react";
import { Routes, Route } from "react-router-dom";

// Importing components
import { Dashboard } from "../containers/dashboard";
import { Login } from "../containers/auth/login";
import { Logout } from "../containers/auth/logout";
import { AuthCallback } from "../containers/auth/callback";
import { Profile } from "../containers/profile/index";
import { Customers } from "../containers/settings/customers";
import { Users } from "../containers/settings/users";
import { LocationSettings } from "../containers/settings/locations";
import { LocationSettingsDetails } from "../containers/settings/locations/details";
import { Reporting } from "../containers/reporting";
import { Scheduling } from "../containers/scheduling";

const RoutesWrapper = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/auth/login/" element={<Login />} />
      <Route path="/auth/logout/" element={<Logout />} />
      <Route path="/auth/callback/" element={<AuthCallback />} />
      <Route path="/profile/" element={<Profile />} />
      <Route path="/scheduling/" element={<Scheduling />} />
      <Route path="/reporting/" element={<Reporting />} />
      <Route path="/settings/customers/" element={<Customers />} />
      <Route path="/settings/locations/" element={<LocationSettings />} />
      <Route
        path="/settings/locations/:id"
        element={<LocationSettingsDetails />}
      />
      <Route path="/settings/users/" element={<Users />} />
    </Routes>
  );
};

export { RoutesWrapper };

import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DraggableList } from "./draggable";

function CheckpointsList({ checkpoints, setCheckpoints, handleRemoveItem }) {
  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className="table w-full">
        <SortableContext
          items={checkpoints}
          strategy={verticalListSortingStrategy}
        >
          {checkpoints.map((checkpoint) => (
            <DraggableList
              key={checkpoint.id}
              item={checkpoint}
              handleRemoveItem={handleRemoveItem}
              handle={true}
            />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setCheckpoints((checkpoints) => {
        const oldIndex = checkpoints.findIndex((c) => c.id === active.id);
        const newIndex = checkpoints.findIndex((c) => c.id === over.id);
        const updatedCheckpoints = arrayMove(checkpoints, oldIndex, newIndex);

        // Update position values
        const reorderedCheckpoints = updatedCheckpoints.map(
          (checkpoint, index) => ({
            ...checkpoint,
            position: index + 1,
          })
        );

        return reorderedCheckpoints;
      });
    }
  }
}

export { CheckpointsList };

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { Button } from "../button";
import { StatusIndicator } from "../statusIndicator";

function SideSheet({
  isOpen,
  onClose,
  title,
  children,
  footerText,
  isAddMode,
  handleSave,
  handleButtonClick,
  size = "standard",
  displayFooter,
  subtitle,
}) {
  let panelWidthClass = "max-w-md";

  if (size === "large") {
    panelWidthClass = "max-w-xl";
  }

  /// Handle the footer ///
  if (!displayFooter && displayFooter !== false) {
    displayFooter = true;
  }

  /// Customize the button text and action ///
  const buttonAction = handleSave || handleButtonClick;
  const buttonLabel = !isAddMode ? "Save" : footerText;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen ${panelWidthClass}`}
                >
                  <div className="flex h-full flex-col bg-white shadow-xl divide-y divide-gray-200">
                    <div className="bg-white px-4 pt-6 sm:px-6">
                      <div className="pb-4 flex items-center justify-between">
                        <Dialog.Title>
                          <div className="flex items-start flex-col">
                            {subtitle && <StatusIndicator text={subtitle} />}
                            <span className="text-lg font-semibold text-gray-600">
                              {title}
                            </span>
                          </div>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 overflow-y-auto">{children}</div>
                    {displayFooter && (
                      <div className="mt-auto">
                        <div className="flex flex-shrink-0 px-4 py-4 bg-white">
                          {buttonAction && (
                            <Button
                              label={buttonLabel}
                              onClick={buttonAction}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { SideSheet };

import React from "react";
import QRCode from "qrcode.react";

const QRPrint = React.forwardRef(
  ({ qrValue }, ref) => (
    console.log("qrValue", qrValue),
    (
      <div
        ref={ref}
        style={{
          //   width: "4in",
          //   height: "4in",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={"m-10 border-2 border-gray-50 p-4"}
      >
        <div className="p-6 border-2 border-gray-100 border-dashed">
          <QRCode value={qrValue} size={256} />
        </div>
        <div className="w-20">
          <p className="p-8 text-xl space-x-2 text-gray-600">
            <strong>Instructions</strong> <br />
            Print this QR code and place in the desired location for your team
            to scan and conduct the checkpoint.
          </p>
        </div>
      </div>
    )
  )
);

export { QRPrint };

import React from "react";
import { SmallHeading } from "../../../components/type/smallHeading";

const ReportingActivityCheckpoints = ({ details }) => {
  console.log("Checkpoint details", details);

  return (
    <>
      <div className="-mt-2">
        <div className="flex pb-2">
          <h3 className="w-[50%] text-gray-500 text-sm">Customer name:</h3>
          <p className="text-gray-600 text-sm">{details.customer_name}</p>
        </div>
        <div className="flex pb-2">
          <h3 className="w-[50%] text-gray-500 text-sm">Location name:</h3>
          <p className="text-gray-600 text-sm">{`${details.location_name}`}</p>
        </div>
        <div className="flex pb-2">
          <h3 className="w-[50%] text-gray-500 text-sm">Checkpoint notes:</h3>
          <p className="text-gray-600 text-sm">{details.checkpoint_notes}</p>
        </div>
      </div>
    </>
  );
};

export { ReportingActivityCheckpoints };

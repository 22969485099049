import React from "react";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

const AppProvider = ({ children }) => {
  console.log("REDIRECT URL", process.env.REACT_APP_KINDE_REDIRECT_URI);

  return (
    <KindeProvider
      clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URI}
      logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URI}
      isDangerouslyUseLocalStorage={process.env.NODE_ENV === "development"}
    >
      {children}
    </KindeProvider>
  );
};

export { AppProvider };

import React from "react";

function BigNumber({ isLoading, count, title, styles }) {
  return (
    <div
      className={`px-6 py-12 bg-gray-50 rounded-lg drop-shadow-100 ${
        styles ? styles : ""
      }`}
    >
      <h2 className="text-sm tracking-wide text-gray-500 pb-2">{title}</h2>
      <p>
        {isLoading ? (
          <span className="text-5xl font-bold">...</span>
        ) : (
          <span className="text-5xl font-bold">
            {String(count).padStart(2, "0")}
          </span>
        )}
      </p>
    </div>
  );
}

export { BigNumber };

import { useState, useEffect, useContext } from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";

function ReportingActivityTypeFilter({ selected, onUpdate, onClear }) {
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchActivityTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/reporting/activities/activity-types`
      );
      if (response.ok) {
        const data = await response.json();

        const augmentedData = data.map((activity) => ({
          ...activity,
          name: activity.type_name,
        }));

        setActivitiesOptions(augmentedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching activity types");
      }
    } catch (error) {
      console.log("Error fetching activity types");
    }
  };

  useEffect(() => {
    fetchActivityTypes();
  }, []);

  return (
    <MultiSelect
      label="Activities"
      options={activitiesOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={isLoading}
    />
  );
}

export { ReportingActivityTypeFilter };

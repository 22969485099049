import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../../../components/navigation";
import { Tabs } from "../../../components/tabs";
import { Crumbs } from "../../../components/crumb";
import { LinkButton } from "../../../components/button/link";
import {
  InformationCircleIcon,
  MapPinIcon,
  DocumentTextIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import { DeleteConfirm } from "../../../components/modal/deleteConfirm";
import { Checkpoints } from "./checkpoints/index";
import { CheckpointDetails } from "./checkpoints/details";
import { SecondaryTabs } from "../../../components/tabs/secondary";
import { Tours } from "./tours";
import { TourDetails } from "./tours/details";
import { LocationDetailsMeta } from "./locationDetailsMeta";
import { set } from "react-hook-form";
import { PageLoadingAnimation } from "../../../components/loading/pageLoading";

function LocationSettingsDetails() {
  const params = useParams();
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);

  /// Get checkpoints ///
  const [checkpointsListData, setCheckpointsListData] = useState();

  const fetchCheckpointsList = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/settings/locations/${params.id}/checkpoints`
      );
      if (response.ok) {
        const data = await response.json();
        setCheckpointsListData(data);
      } else {
        console.error("Error fetching checkpoints list");
      }
    } catch (error) {
      console.error("Error fetching checkpoints list", error);
    }
  });

  /// Checkpoints ///
  const [isCheckpointsAddMode, setIsCheckpointsAddMode] = useState(false);
  const [selectedCheckpointType, setSelectedCheckpointType] = useState(null);

  const handleCheckpointsAddMode = (id, type) => {
    setSelectedCheckpointId(id);
    setSelectedCheckpointType(type);
    setIsCheckpointsAddMode(false);
    toggleCheckpointsOpen();
  };

  const [isCheckpointsOpen, setIsCheckpointsOpen] = useState(false);
  const toggleCheckpointsOpen = () => {
    setIsCheckpointsOpen(!isCheckpointsOpen);
    if (isCheckpointsOpen) {
      setSelectedCheckpointId(null);
    }
  };

  const [selectedCheckpointId, setSelectedCheckpointId] = useState(null);
  const handleSelectedCheckpointsId = (id) => {
    setSelectedCheckpointId(id);
  };

  /// Selected checkpoint details ///
  const [selectedCheckpointDetails, setSelectedCheckpointDetails] = useState({
    checkpoint_name: "",
    checkpoint_description: "",
    checkpoint_type: "",
  });

  const fetchSelectedCheckpointDetails = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/settings/locations/checkpoints/${selectedCheckpointId}`
      );
      if (response.ok) {
        const data = await response.json();
        const { checkpoint_name, checkpoint_description, checkpoint_type } =
          data[0];

        setSelectedCheckpointDetails({
          checkpoint_name,
          checkpoint_description,
          checkpoint_type,
        });
      } else {
        console.error("Error fetching selected checkpoint details");
      }
    } catch (error) {
      console.error("Error fetching selected checkpoint details", error);
    }
  }, [selectedCheckpointId, setSelectedCheckpointDetails]);

  /// Tours ///
  const [tours, setTours] = useState([]);
  const [isToursAddMode, setIsToursAddMode] = useState(false);

  const handleToursAddMode = (id) => {
    setSelectedTourId(id);
    setIsToursAddMode(false);
    toggleToursOpen();
  };

  const [isToursOpen, setIsToursOpen] = useState(false);
  const toggleToursOpen = () => {
    setIsToursOpen(!isToursOpen);
    if (isToursOpen) {
      setSelectedTourId(null);
    }
  };

  const [selectedTourId, setSelectedTourId] = useState(null);
  const handleSelectedTourId = (id) => {
    setSelectedTourId(id);
  };

  /// Tour list data ///
  const [toursListData, setToursListData] = useState();

  const fetchToursList = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/settings/locations/${params.id}/tours`
      );
      if (response.ok) {
        const data = await response.json();
        setToursListData(data);
      } else {
        console.error("Error fetching tours list");
      }
    } catch (error) {
      console.error("Error fetching tours list", error);
    }
  });

  /// Selected tour details ///

  const [selectedTourDetails, setSelectedTourDetails] = useState({
    tour_name: "",
    tour_description: "",
  });

  const fetchSelectedTourDetails = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/settings/locations/tours/${selectedTourId}`
      );
      if (response.ok) {
        const data = await response.json();
        const { tour_name, tour_description } = data[0];

        setSelectedTourDetails({ tour_name, tour_description });
      } else {
        console.error("Error fetching selected tour details");
      }
    } catch (error) {
      console.error("Error fetching selected tour details", error);
    }
  }, [selectedTourId, setSelectedTourDetails]);

  /// Tour checkpoints ///

  const [selectedTourCheckpoints, setSelectedTourCheckpoints] = useState([]);
  const handleSelectedTourCheckpoints = (checkpoints) => {
    setSelectedTourCheckpoints(checkpoints);
  };

  /// Delete checkpoints from both checkpoints list and tour details list ///
  const [isCheckpointsDeleteOpen, setIsCheckpointsDeleteOpen] = useState(false);
  const [selectedCheckpointDeleteItem, setSelectedCheckpointDeleteItem] =
    useState({
      identifier: "",
      id: "",
    });

  const [deleteCheckpointSuccess, setDeleteCheckpointSuccess] = useState({
    displaying: false,
    message: "Checkpoint has been deleted",
  });

  const handleDeleteCheckpointSuccess = () => {
    fetchCheckpointsList();

    setDeleteCheckpointSuccess({
      displaying: true,
      message: "Checkpoint has been deleted",
    });
    setTimeout(() => {
      setDeleteCheckpointSuccess({
        displaying: false,
        message: deleteCheckpointSuccess.message,
      });
    }, 3000);
  };

  const deleteCheckpoint = (checkpointId) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/checkpoints/delete/${checkpointId}`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // Handle the result
        console.log("Checkpoint deleted successfully", result);
        return result; // Ensure you return something here if needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle any errors that occur during submission
        throw error; // Re-throw the error if you want to propagate it
      });
  };

  function handleDeleteCheckpoint(identifier, id) {
    setSelectedCheckpointDeleteItem({ identifier, id }); // Correctly using the setter function
    setIsCheckpointsDeleteOpen(true);
  }

  const toggleDeleteCheckpointState = (setState, state) => {
    setState(!state);
  };

  /// Details Meta ///
  const [isDetailsMetaOpen, setIsDetailsMetaOpen] = useState(false);
  const toggleDetailsMetaOpen = () => {
    setIsDetailsMetaOpen(!isDetailsMetaOpen);
  };

  /// Fetch location details ///
  const [backendData, setBackendData] = useState({
    location_name: "",
    location_address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    location_notes: "",
    customer_id: "",
    customer_name: "",
    logo_url: "",
    contacts: [],
  });

  const fetchLocationDetails = async () => {
    if (initialLoadCompleted) {
      setInitialLoadCompleted(true);
    }

    const fetchPromise = fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/${params.id}`
    )
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          const {
            location_name,
            street,
            city,
            state,
            zip,
            country,
            location_notes,
            customer_id,
            customer_name,
            logo_url,
            contacts,
          } = data[0];
          setBackendData({
            location_name,
            location_address: {
              street,
              city,
              state,
              zip,
              country,
            },
            location_notes,
            customer_id,
            customer_name,
            logo_url,
            contacts,
          });
        } else {
          // Handle HTTP errors e.g., 404, 500 etc.
          console.error("HTTP Error: ", response.statusText);
          // Optionally, update state to display an error message
        }
      })
      .catch((error) => {
        // Handle network errors, parsing errors, etc.
        console.error("Fetching error: ", error.message);
        // Optionally, update state to display an error message
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setInitialLoadCompleted(true); // Set initial load completed after the first fetch
    });
  };

  // const fetchLocationDetails = () => {
  //   fetch(`${process.env.REACT_APP_API_URL}/web/settings/locations/${params.id}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const {
  //         location_name,
  //         street,
  //         city,
  //         state,
  //         zip,
  //         country,
  //         location_notes,
  //         customer_id,
  //         customer_name,
  //         logo_url,
  //         contacts,
  //       } = data[0];

  //       setBackendData({
  //         location_name,
  //         location_address: {
  //           street,
  //           city,
  //           state,
  //           zip,
  //           country,
  //         },
  //         location_notes,
  //         customer_id,
  //         customer_name,
  //         logo_url,
  //         contacts,
  //       });
  //     });
  // };

  const handleSuccess = () => {
    fetchLocationDetails();
  };

  useEffect(() => {
    fetchLocationDetails();
  }, [params.id]);

  /// Tabs ///

  const secondaryTabs = [
    {
      name: "Checkpoints",
      href: "#",
      current: true,
      component: (
        <Checkpoints
          checkpoints={checkpointsListData}
          setCheckpoints={setCheckpointsListData}
          handleDeleteCheckpoint={handleDeleteCheckpoint}
          fetchCheckpointsList={fetchCheckpointsList}
          checkpointsListData={checkpointsListData}
          handleCheckpointsAddMode={handleCheckpointsAddMode}
        />
      ),
    },
    {
      name: "Tours",
      href: "#",
      current: false,
      component: (
        <Tours
          handleToursAddMode={handleToursAddMode}
          fetchToursList={fetchToursList}
          toursListData={toursListData}
          setIsCheckpointsAddMode={setIsCheckpointsAddMode}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState("Checkpoints");

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <Navigation heading="Settings" current="settings" />
      <Tabs current="Locations" section="settings" />
      <Crumbs
        section="Locations"
        previous="/settings/locations/"
        active={backendData.location_name}
      />

      <main className="py-4 lg:pl-80">
        <PageLoadingAnimation isLoading={!initialLoadCompleted} />
        <div className="sm:px-4 lg:px-6">
          <div
            className="text-sm font-medium py-4 sm:px-2 lg:px-2 hover:bg-gray-50 cursor-pointer rounded-md"
            onClick={toggleDetailsMetaOpen}
          >
            <div className="inline-block mr-12">
              <InformationCircleIcon
                className="h-6 w-6 mr-2 inline text-gray-500"
                aria-hidden="true"
              />
              <span className="text-gray-600">{backendData.location_name}</span>
            </div>
            <div className="inline-block mr-12">
              <MapPinIcon
                className="h-6 w-6 mr-2 inline text-gray-500"
                aria-hidden="true"
              />
              <span className="text-gray-600">
                {backendData.location_address.street}
              </span>
            </div>
            <div className="inline-block mr-12">
              <DocumentTextIcon
                className="h-6 w-6 mr-2 inline text-gray-500"
                aria-hidden="true"
              />
              <span className="text-gray-600">
                {!backendData.location_notes ? "No notes" : "View notes"}
              </span>
            </div>
            <div className="inline-block mr-12">
              <img
                src={backendData.logo_url}
                className="h-6 w-6 mr-2 inline text-gray-500"
                aria-hidden="true"
              />
              <span className="text-gray-600">
                {!backendData.customer_name
                  ? "No notes"
                  : backendData.customer_name}
              </span>
            </div>
            <div className="inline-block mr-12">
              <DevicePhoneMobileIcon
                className="h-6 w-6 mr-2 inline text-gray-500"
                aria-hidden="true"
              />
              <span className="text-gray-600">
                {backendData.contacts.length === 1
                  ? "1 contact"
                  : backendData.contacts.length > 1
                  ? backendData.contacts.length + " contacts"
                  : "No contacts"}
              </span>
            </div>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-6">
          <div className="flex justify-between items-center py-2">
            <div className="hidden sm:block">
              <nav
                className="flex px-2 py-2 mb-2 bg-gray-50 rounded-md"
                aria-label="Tabs"
              >
                {secondaryTabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(tab.name);
                    }}
                    className={classNames(
                      activeTab === tab.name
                        ? "bg-white text-gray-900 font-medium"
                        : "hover:text-gray-500",
                      "rounded-md px-6 py-1 text-sm font-normal"
                    )}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="col-start-4 col-end-7 justify-self-end">
              {activeTab === "Checkpoints" && (
                <LinkButton
                  type="function"
                  text="Create checkpoint"
                  onClick={() => {
                    setIsCheckpointsAddMode(true);
                    toggleCheckpointsOpen();
                  }}
                />
              )}
              {activeTab === "Tours" && (
                <LinkButton
                  type="function"
                  text="Create tour"
                  onClick={() => {
                    setIsToursAddMode(true);
                    setIsCheckpointsAddMode(true);
                    toggleToursOpen();
                  }}
                />
              )}
            </div>
          </div>
          {/* <Checkpoints
            checkpoints={checkpoints}
            setCheckpoints={setCheckpoints}
          /> */}
          <SecondaryTabs tabs={secondaryTabs} activeTab={activeTab} />
        </div>
      </main>
      <CheckpointDetails
        isOpen={isCheckpointsOpen}
        toggleOpen={toggleCheckpointsOpen}
        selectedCheckpointDetails={selectedCheckpointDetails}
        isAddMode={isCheckpointsAddMode}
        selectedTourId={selectedTourId}
        selectedCheckpointId={selectedCheckpointId}
        setSelectedTourCheckpoints={handleSelectedTourCheckpoints}
        // selectedTourCheckpoints={selectedTourCheckpoints}
        setCheckpoints={setCheckpointsListData}
        fetchCheckpoints={fetchCheckpointsList}
        checkpoints={selectedTourCheckpoints}
        fetchToursList={fetchToursList}
        fetchSelectedCheckpointDetails={fetchSelectedCheckpointDetails}
        selectedCheckpointType={selectedCheckpointType}
      />

      <DeleteConfirm
        isOpen={isCheckpointsDeleteOpen}
        toggleOpen={() =>
          toggleDeleteCheckpointState(
            setIsCheckpointsDeleteOpen,
            isCheckpointsDeleteOpen
          )
        }
        selectedDeleteItem={selectedCheckpointDeleteItem} // Ensure this is the correct state
        handleDeleteSuccess={handleDeleteCheckpointSuccess}
        onDelete={deleteCheckpoint}
      />

      <TourDetails
        isOpen={isToursOpen}
        toggleOpen={toggleToursOpen}
        setTours={setTours}
        // tours={tours}
        isAddMode={isToursAddMode}
        selectedTourId={selectedTourId}
        toggleCheckpointsOpen={toggleCheckpointsOpen}
        setSelectedTourCheckpoints={handleSelectedTourCheckpoints}
        selectedTourCheckpoints={selectedTourCheckpoints}
        selectedTourDetails={selectedTourDetails}
        fetchSelectedTourDetails={fetchSelectedTourDetails}
        fetchToursList={fetchToursList}
      />

      <LocationDetailsMeta
        isOpen={isDetailsMetaOpen}
        toggleOpen={toggleDetailsMetaOpen}
        locationData={backendData}
        updateLocationData={fetchLocationDetails}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

export { LocationSettingsDetails };

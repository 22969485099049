import React from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";

function IconButton({ type, onClick, style }) {
  let buttonStyle;

  style === "padded" &&
    (buttonStyle =
      "rounded-md mt-2 px-4 py-2 text-sm font-semibold bg-gray-50 text-gray-500 ring-1 ring-inset ring-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6");
  style === "tight" &&
    (buttonStyle =
      "rounded-md px-3 py-2 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6");

  return (
    <div className="col-span-full">
      <button onClick={onClick} type="button" className={buttonStyle}>
        {type === "add" && <PlusCircleIcon className="h-6 w-6 inline" />}
        {type === "remove" && <MinusCircleIcon className="h-6 w-6 inline" />}
      </button>
    </div>
  );
}

export { IconButton };

import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { SideSheet } from "../../components/sheet/sideSheet";
import { SmallHeading } from "../../components/type/smallHeading";
import { FixedLocationMap } from "../../components/maps/FixedLocationMap";
import { ReportingActivityCheckpoints } from "./detailsContainers/checkpointDetails";
import { ReportingActivityEmergencyCalls } from "./detailsContainers/emergencyCallsDetails";
import { ReportingActivityIncidentReports } from "./detailsContainers/incidentReportsDetails";
import { UserCircleIcon, CalendarIcon } from "@heroicons/react/24/outline";

function ReportingActivityDetails({
  isOpen,
  toggleOpen,
  selectedReportingActivityId,
}) {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;
  const [activityDetails, setActivityDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchActivityDetails = async () => {
    if (!selectedReportingActivityId) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/reporting/activities/${selectedReportingActivityId}`
      );
      if (response.ok) {
        const data = await response.json();
        setActivityDetails(data);
      } else {
        console.log("Error fetching activity details");
      }
    } catch (error) {
      console.log("Error fetching activity details");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityDetails();
  }, [selectedReportingActivityId]);

  return (
    <div>
      <SideSheet
        isOpen={isOpen}
        onClose={toggleOpen}
        title="Activity details"
        subtitle={activityDetails?.activity_type}
        displayFooter={false}
      >
        {isLoading ? (
          <div className="pb-4 text-center">
            <span className="text-gray-500">Loading...</span>
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-3">
              <div className="pb-4">
                <FixedLocationMap
                  latitude={activityDetails?.latitude}
                  longitude={activityDetails?.longitude}
                />
              </div>

              <div className="flex">
                <p className="mb-2 mr-6">
                  <UserCircleIcon className="w-6 h-6 mr-1 text-gray-500 inline-block" />
                  <span className="text-sm text-gray-600">
                    {activityDetails?.first_name} {activityDetails?.last_name}
                  </span>
                </p>
                <p>
                  <CalendarIcon className="w-6 h-6 mr-1 text-gray-500 inline-block" />
                  <span className="text-sm text-gray-600">
                    {new Date(activityDetails?.activity_date).toLocaleString()}
                  </span>
                </p>
              </div>
              <div className="-mt-6">
                {activityDetails?.activity_type_id === 1 && (
                  <ReportingActivityCheckpoints details={activityDetails} />
                )}
                {activityDetails?.activity_type_id === 2 && (
                  <ReportingActivityEmergencyCalls details={activityDetails} />
                )}
                {activityDetails?.activity_type_id === 3 && (
                  <ReportingActivityIncidentReports details={activityDetails} />
                )}

                <div className="pt-6">
                  <SmallHeading>Media</SmallHeading>
                  <div className="pt-2">
                    {activityDetails.media_urls &&
                    activityDetails.media_urls.length > 0 ? (
                      activityDetails.media_urls?.map((media, index) => (
                        <div key={index} className="flex pb-2">
                          <img
                            src={media}
                            alt="media"
                            className="w-45% h-45%"
                          />
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-gray-500">No media provided</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SideSheet>
    </div>
  );
}

export { ReportingActivityDetails };

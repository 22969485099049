import React, { useEffect, useState, useContext } from "react";
import Navigation from "../../components/navigation";
import { ClockedInLocationMap } from "../../components/maps/ClockedInLocationMap";
import { DashboardUserFilter } from "./userFilter";
import { PageLoadingAnimation } from "../../components/loading/pageLoading";
import { AuthContext } from "../../contexts/AuthContext";
import { BigNumber } from "../../components/visualization/bigNumber";
import { MegaphoneIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { format, subDays } from "date-fns";

function Dashboard() {
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [isMapDataLoading, setIsMapDataLoading] = useState(true);

  const [isIncidentCountLoading, setIsIncidentCountLoading] = useState(false);
  const [incidentCount, setIncidentCount] = useState(0);

  const [isEmergencyContactCountLoading, setIsEmergencyContactCountLoading] =
    useState(false);
  const [emergencyContactCount, setEmergencyContactCount] = useState(0);

  const [onBreakCount, setOnBreakCount] = useState(0);

  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;

  /// Fetch initial users and locations ///
  useEffect(() => {
    if (orgId) {
      fetchUsers();
    }
  }, [orgId]);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      fetchLocations();
    }
  }, [selectedUsers]);

  const fetchUsers = async () => {
    if (!orgId) return;

    if (initialLoadCompleted) {
      setInitialLoadCompleted(true);
    }

    const url = new URL(
      `${process.env.REACT_APP_API_URL}/web/dashboard/users`,
      window.location.origin
    );
    url.searchParams.append("org_id", orgId);

    const fetchPromise = fetch(url.toString())
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUsersOptions(
          data.map((user) => ({
            ...user,
            name: `${user.first_name} ${user.last_name}`,
          }))
        );
        setSelectedUsers(data.map((user) => user.id));
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setInitialLoadCompleted(true); // Set initial load completed after the first fetch
    });
  };

  const fetchLocations = async () => {
    setIsMapDataLoading(true);
    const user_ids = selectedUsers.join(",");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/web/dashboard/users/locations?user_ids=${user_ids}`
    );
    const data = await response.json();
    setMapData(data.locations);
    setIsMapDataLoading(false);
  };

  /// Handle selected users ///
  const handleSelectedUsers = (optionId) => {
    setSelectedUsers((currentSelected) => {
      return currentSelected.includes(optionId)
        ? currentSelected.filter((id) => id !== optionId)
        : [...currentSelected, optionId];
    });
  };

  /// Clear selected users ///
  const clearSelectedUsers = () => setSelectedUsers([]);

  /// Handle display for today's date in the header ///
  const displayDateRange = () => {
    const now = new Date();
    const yesterday = subDays(now, 1);

    const displayDate = (date) => {
      return format(date, "EE h:mm a");
    };

    return (
      <p>
        {displayDate(yesterday)} - {displayDate(now)}
      </p>
    );
  };

  /// Handle date for getting data ///
  const getDataDateRange = () => {
    const formatDate = (date) => format(date, "yyyy-MM-dd HH:mm:ss");

    const startDate = subDays(new Date(), 1);
    const endDate = new Date(); // current date and time

    return {
      start: formatDate(startDate),
      end: formatDate(endDate),
    };
  };

  // console.log(getDataDateRange().start, getDataDateRange().end);

  /// Get Incident count ///
  useEffect(() => {
    if (!orgId) return;

    setIsIncidentCountLoading(true);
    const fetchIncidentCount = async () => {
      const { start, end } = getDataDateRange();
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/v1/activities/incident-reports/count`,
        window.location.origin
      );
      url.searchParams.append("org_id", orgId);
      url.searchParams.append("start_date", start);
      url.searchParams.append("end_date", end);

      console.log(url.toString());

      try {
        const response = await fetch(url.toString());
        const data = await response.json();
        setIncidentCount(data.count);
        setIsIncidentCountLoading(false);
      } catch (error) {
        console.error("Error fetching incident count:", error);
      }
    };

    fetchIncidentCount();
  }, [orgId]);

  /// Get Emergency Contact count ///
  useEffect(() => {
    if (!orgId) return;

    setIsEmergencyContactCountLoading(true);
    const fetchEmergencyContactCount = async () => {
      const { start, end } = getDataDateRange();
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/v1/activities/emergency-contacts/entries/count`,
        window.location.origin
      );
      url.searchParams.append("org_id", orgId);
      url.searchParams.append("start_date", start);
      url.searchParams.append("end_date", end);

      console.log(url.toString());

      try {
        const response = await fetch(url.toString());
        const data = await response.json();

        setEmergencyContactCount(data.count);

        setIsEmergencyContactCountLoading(false);
      } catch (error) {
        console.error("Error fetching incident count:", error);
      }
    };

    fetchEmergencyContactCount();
  }, [orgId]);

  return (
    <div>
      <Navigation
        heading="Dashboard"
        subHeading={displayDateRange()}
        current="dashboard"
      />
      <main className="lg:pl-80">
        <PageLoadingAnimation isLoading={!initialLoadCompleted} />
        <div className="px-4 sm:px-6 lg:px-8 relative -bottom-12 z-10">
          <div className="flex items-center ">
            <div className="flex-1">
              <div className="pl-3 inline mr-4">
                <DashboardUserFilter
                  selected={selectedUsers}
                  onUpdate={handleSelectedUsers}
                  onClear={clearSelectedUsers}
                />
              </div>
            </div>
            <div className="flex justify-end"></div>
          </div>
        </div>
        <div>
          {isMapDataLoading ? (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-center h-96">
                <p className="text-lg text-gray-500">Loading...</p>
              </div>
            </div>
          ) : mapData && mapData.length > 0 ? (
            <div className="px-4 sm:px-6 lg:px-8">
              <ClockedInLocationMap clockedInLocationData={mapData} />
            </div>
          ) : (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-center h-96">
                <p className="text-lg text-gray-500">
                  No user select to show on a map
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="px-4 sm:px-6 lg:px-8 pt-6 flex">
          <div className="bg-black w-1/3 rounded-lg px-6 py-6 mr-6">
            <div className="flex items-center">
              <MegaphoneIcon className="h-12 w-12 pr-4 text-white" />
              <h2 className="text-sm  text-white tracking-wider">
                Opspot free plan usage
              </h2>
            </div>
            <ul className="text-gray-300 text-sm pl-12">
              <li className="flex items-center pb-1">
                <CheckCircleIcon className="h-4 w-4 mr-2 inline-block text-success" />
                <span>x/y users</span>
              </li>
              <li className="flex items-center pb-1">
                <CheckCircleIcon className="h-4 w-4 mr-2 inline-block text-success" />
                <span>x/y locations</span>
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="h-4 w-4 mr-2 inline-block text-success" />
                <span>x/y checkpoints</span>
              </li>
            </ul>
          </div>
          <div className="flex w-2/3">
            <BigNumber
              isLoading={isIncidentCountLoading}
              count={incidentCount}
              title="Incidents submitted"
              styles="w-1/3 mr-3"
            />
            <BigNumber
              isLoading={isEmergencyContactCountLoading}
              count={emergencyContactCount}
              title="Emergency calls"
              styles="w-1/3 mr-3"
            />
            <BigNumber
              isLoading={false}
              count={onBreakCount}
              title="On break"
              styles="w-1/3"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export { Dashboard };
